<template>
    <b-row class="match-height">
      <b-col lg="12">
        <b-card title="Confirm Purchase Order Assets">
              <b-button
                type="button"
                variant="primary"
                class="mr-1"
                @click="previous()"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="16"
                  class="align-middle"
                />
                Back
              </b-button>
              <b-form @submit.prevent="submitReceivePurchaseOrder">
    <b-row v-for="(item, index) in items" :key="index">
      <b-col cols="12">
        <hr />
        <h3>( {{ index + 1 }} ) {{ item.item.item_code }} </h3>
        <br />
        <b-form-group
          label="Item ID "
          label-for="itemId"
          label-cols-md="12"
        >
          <b-form-input
            id="itemId"
            :value="item.item.item_code"
            placeholder="itemId"
            readonly
          />
        </b-form-group>
        <b-form-group
          label="Asset ID "
          label-for="assetId"
          label-cols-md="12"
        >
          <b-form-input
            id="assetId"
            v-model="item.asset_number"
            placeholder="Asset ID"
            readonly
          />
        </b-form-group>
        <b-form-group
          label="Local ID"
          label-for="localId"
          label-cols-md="12"
        >
          <b-form-input
            id="localId"
            v-model="item.asset_local_id"
            placeholder="local ID"
            readonly
          />
          </b-form-group>
        <b-form-group
          label="Ref ID "
          label-for="refId"
          label-cols-md="12"
        >
          <b-form-input
            id="refId"
            v-model="item.asset_ref_id"
            placeholder="Ref ID"
            readonly
          />
        </b-form-group>
        <b-form-group
          label="Category *"
          label-for="category"
          label-cols-md="12"
        >
          <b-form-input
            id="assetCategory"
            v-model="item.asset_category"
            placeholder="Asset Category"
            readonly
          />
        </b-form-group>
        <b-form-group
          label="Description *"
          label-for="description"
          label-cols-md="12"
        >
          <b-form-input
            id="assetDescription"
            v-model="item.asset_description"
            placeholder="Asset Description"
            readonly
          />
        </b-form-group>
        <b-form-group
          label="GPS"
          label-for="gps"
          label-cols-md="12"
        >
          <b-form-input
            id="assetGPS"
            v-model="item.asset_gps"
            placeholder="Asset GPS"
            readonly
          />
        </b-form-group>
        <b-form-group
          label="Date of Acquisition *"
          label-cols-md="12"
        >
                <b-form-input
                  v-model="item.asset_date_of_acquistion"
            readonly
                />
        </b-form-group>
        <b-form-group
                label="Acquisition Value*"
                label-for="assetAcquisitionValue"
                label-cols-md="12"
              >
                <b-form-input
                  id="assetAcquisitionValue"
                  v-model="item.asset_acquistion_value"
                  placeholder="Asset Acquisition Value"
            readonly
                />
              </b-form-group>
              <b-form-group
                label="Depreciation Method *"
                label-for="assetDepreciationMethod"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="item.asset_depreciation_method"
            readonly
                />
              </b-form-group>
              <b-form-group
                label="Residual Value *"
                label-for="assetResidualValue"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="item.asset_residual_value"
            readonly
                />
              </b-form-group>

              <b-form-group
                label="Market Value *"
                label-for="assetMarketValue"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="item.asset_market_value"
                  placeholder="Asset Market Value"
            readonly
                />
              </b-form-group>
              <b-form-group
                label="Asset Condition *"
                label-for="assetAssetCondition"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="item.asset_condition"
            readonly
                />
              </b-form-group>
              <b-form-group
                label="Asset Address *"
                label-for="assetAddress"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="item.asset_address"
            readonly
                />
              </b-form-group>
              <b-form-group
                label="Asset Responsible *"
                label-for="assetResponsible"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="item.asset_responsible"
            readonly
                />
              </b-form-group>
              <b-form-group
                label="Serial Number *"
                label-for="assetSerialNumber"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="item.asset_serial_no"
            readonly
                />
              </b-form-group>
              <b-form-group
                label="Warranty *"
                label-for="assetWarranty"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="item.asset_warranty"
            readonly
                />
              </b-form-group>
      </b-col>
    </b-row>
    <!-- submit button -->
    <b-button type="submit" variant="primary">
      Confirm
    </b-button>
  </b-form>
        </b-card>
      </b-col>
    </b-row>
  </template>
  <script>
  import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormDatepicker,
    BFormFile,
    BFormCheckbox,
  } from "bootstrap-vue"
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
  import axios from "axios"
  import moment from "moment"
  import Ripple from "vue-ripple-directive"
  import { ref } from "@vue/composition-api"

  const VersionCheck = ""
  const file = ""
  const items = []
  const chosenPR = ref()
  const chosens = []
  const shipmentTo = ""
  const itemId = ""
  const purchaseOrderProject = ""
  const purchaseOrderCostCenter = ""
  const purchaseOrderQtyRequested = 0
  const purchaseOrderStatus = ""
  const purchaseOrderNotes = ""
  const deliverySchedule = ""
  const modalReferenceShow = false
  const itemReference = ""
  const itemLink = ""
  const itemPrice = ""
  const itemDescription = ""
  const contacts = []
  const branches = []
  const references = []
  const currencies = []
  const returns = []
  const chosenBranch = ""
  const methods = ["Metode Garis Lurus", "Metode Saldo Menurun", "Metode Unit Produksi", "Metode Jam Kerja", "Metode Penyusutan Akselerasi"]
  const conditions = ["Baik", "Sedang", "Buruk"]
  const chosenContact = ""
  const termOfPayment = ""
  const billingAddress = ""
  const deliveryAddress = ""
  const purchaseOrderDetailId = ""
  const modalReturnShow = false
  const itemCurrency = ""
  const returnItemQty = ""
  const chosenItem = ""
  const modalReferenceHistoryShow = false
  const submitButtonDisabled = false

  export default {
    components: {
      BFormFile,
      BFormCheckbox,
      BFormDatepicker,
      BListGroup,
      BListGroupItem,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BButton,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        conditions,
        methods,
        submitButtonDisabled,
        chosenItem,
        returns,
        returnItemQty,
        purchaseOrderDetailId,
        modalReturnShow,
        itemCurrency,
        currencies,
        deliveryAddress,
        chosenBranch,
        branches,
        termOfPayment,
        billingAddress,
        modalReferenceHistoryShow,
        chosenContact,
        contacts,
        itemId,
        deliverySchedule,
        file,
        modalReferenceShow,
        itemReference,
        itemLink,
        itemPrice,
        itemDescription,
        references,
        VersionCheck,
        items,
        chosenPR,
        chosens,
        shipmentTo,
        purchaseOrderProject,
        purchaseOrderCostCenter,
        purchaseOrderQtyRequested,
        purchaseOrderStatus,
        purchaseOrderNotes,
      }
    },
    mounted() {
      this.getDetails()
      this.chosens = []
    },
    created() {
      window.setInterval(() => {
        this.removeReadOnly()
      }, 1000)
    },
    methods: {
      removeReadOnly() {
        const searches = document.getElementsByClassName("vs__search")
        for (let i = 0; i < searches.length; i += 1) {
          searches[i].removeAttribute("readonly")
        }
      },
      getDetails() {
        const userToken = this.$cookies.get("userToken")
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        }
        const itemReceive = JSON.parse(localStorage.getItem("AssetReceiveDetail"))
        axios.get(
            `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ASSET_CHECK}${itemReceive.purchase_order_warehouse_receive_id}`,
            { headers },
          )
          .then(response => {
            if (response.data.success === true) {
              const temp = []
              response.data.data.forEach(elem => {
                  temp.push(elem)
              })
              this.items = temp
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Fetching Data success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Fetching Data failed",
                  icon: "CheckIcon",
                  variant: "danger",
                },
              })
            }
          })
      },
      submitReceivePurchaseOrder() {
    this.submitButtonDisabled = true
    const userToken = this.$cookies.get("userToken")
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${userToken}`,
    }
    const item = JSON.parse(localStorage.getItem("AssetReceiveDetail"))
    let assetIdFilled = true
    this.chosens.map(o => {
      if (o.asset_id === "") {
        assetIdFilled = false
      }
    })
    if (assetIdFilled === true) {
      const id = item.purchase_order_warehouse_receive_id
      const formData = new FormData()
      formData.append("receive_items", JSON.stringify(this.items))
      formData.append(
        "purchase_order_purchasing_receive_id",
        item.purchase_order_warehouse_receive_purchasing_receive_id,
      )
      formData.append("time", moment())

      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_ASSET_PURCHASE_ORDER}${id}`
      axios
        .post(url, formData, { headers })
        .then(response => {
          if (response.data.success === true) {
            this.submitButtonDisabled = false
            this.chosens = []
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Confirm Asset Purchase Order Success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
            this.$router.replace({
              name: "apps-procurement-assets-list",
            })
          } else {
            this.submitButtonDisabled = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Confirm Asset Purchase Order Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.submitButtonDisabled = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Confirm Asset Purchase Order Asset Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    } else {
      this.submitButtonDisabled = false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Please put all Asset IDs!",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      })
    }
},
      getOptionLabel(option) {
        return (option && option.label) || ""
      },
      inputSearch(search) {
        console.log(search)
      },
      handleFilesUpload(e) {
        this.file = e.target.files || e.dataTransfer.files
      },
      addReference(val) {
        this.modalReferenceShow = true
        this.itemId = val
      },
      showReference(val) {
        const userToken = this.$cookies.get("userToken")
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        }
        this.itemId = val
        this.modalReferenceHistoryShow = true
        axios
          .get(`${process.env.VUE_APP_API_GET_ITEM_LOOKUP}${val}`, { headers })
          .then(response => {
            if (response.data.success === true) {
              this.references = response.data.data
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Get References Failed",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              })
            }
          })
          .catch(e => {
            console.log(e)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get References Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          })
      },
      dateSimple(value, format = "MM/DD/YYYY") {
        return moment(String(value)).format(format)
      },
      returnItem(id) {
        this.purchaseOrderDetailId = id
        this.modalReturnShow = true
      },
      saveReturnItem() {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Return Item Saved",
            icon: "CheckIcon",
            variant: "success",
          },
        })
      },
      getOptionKey(option) {
        return (option && option.value) || ""
      },
      putItem(val) {
        if (this.chosens.length > 0) {
            let item = null
            this.items.map(elem => {
            if (elem.value === val.value) {
                item = {
                itemId: elem.itemId,
                label: elem.label,
                unit: elem.unit,
                value: elem.value,
                asset_id: elem.asset_id,
                received: elem.received,
                checked: elem.checked,
                max: elem.max,
                shelves: elem.shelves,
                id: this.chosens.length + 1,
                }

                // Check if elem.itemId exists in this.chosens
                if (!this.chosens.some(chosenItems => chosenItems.itemId === elem.itemId)) {
                this.chosens.push(item)
                }
            }
            })
        } else {
          let item = null
          this.items.map(elem => {
            if (elem.value === val.value) {
              item = {
                itemId: elem.itemId,
                label: elem.label,
                unit: elem.unit,
                value: elem.value,
                qty: elem.qty,
                max: elem.max,
                received: elem.received,
                checked: elem.checked,
                shelves: elem.shelves,
                id: this.chosens.length,
              }
            }
          })

          this.chosens.push(item)
        }
      },
      removeRow(val) {
        this.chosens = this.chosens.filter(obj => obj.id !== val)
      },
        previous() {
            this.$router.push({ name: 'apps-assets-receive-list' })
        },
    },
  }
  </script>
